import {EndpointErrorHandling} from 'hslayers-ng';
export default [
    /*{
        title: "Datatank",
        url: "http://ewi.mmlab.be/otn/api/info",
        type: "datatank"
    },
    {
        title: "Datasets",
        url: "http://otn-dev.intrasoft-intl.com/otnServices-1.0/platform/ckanservices/datasets",
        language: 'eng',
        type: "ckan",
        download: true
    }, */
    {
        title: "Layman",
        url: `https://${window.location.hostname}`,
        user: 'browser',
        type: "layman",
        onError: {
            compositionLoad: EndpointErrorHandling.ignore,
            addDataCatalogueLoad: EndpointErrorHandling.ignore
        },
        version: '1.3.3',
        liferayProtocol: 'https'
    },
   /* {
        title: "OTN Hub",
        url: "http://opentnet.eu/php/metadata/csw/",
        language: 'eng',
        type: "micka",
        code_list_url: 'http://opentnet.eu/php/metadata/util/codelists.php?_dc=1440156028103&language=eng&page=1&start=0&limit=25&filter=%5B%7B%22property%22%3A%22label%22%7D%5D'
    },*/ 
    {
        title: "Micka",
        url: "https://micka.smartafrihub.com/csw",
        language: 'eng',
        type: "micka",
        onError: {
            compositionLoad: EndpointErrorHandling.ignore,
            addDataCatalogueLoad: EndpointErrorHandling.ignore
        },
        code_list_url: 'https://micka.smartafrihub.com/util/codelists.php?_dc=1440156028103&language=eng&page=1&start=0&limit=25&filter=%5B%7B%22property%22%3A%22label%22%7D%5D'
    }
]