import { Injectable } from '@angular/core';
import { default_layers, aoiLayer, setupLayers } from './layers/layers';
import { translationOverrides } from './translations';
import View from 'ol/View';
import { HsConfig, HsPanelContainerService, HsSidebarService, HsMapService, HsLanguageService, HsShareUrlService, HsDrawService} from 'hslayers-ng';
import {HsSensorsComponent} from 'hslayers-sensors';
import { transform, transformExtent } from 'ol/proj';
import datasourceList from './datasource-list';
import { analysesLvTranslations } from './analysis/translations.lv';
import { analysesEnTranslations } from './analysis/translations.en';
import { FieAnalysisService } from './analysis/analysis.service';
import meteoLegends from './layers/meteo-legends';
import {HsToastService} from 'hslayers-ng';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LiferayService } from './liferay';

@Injectable({providedIn: 'root'})
export class MainService {
  assetsPath: string;
  constructor(
    private hsSidebarService: HsSidebarService,
    private hsPanelContainerService: HsPanelContainerService,
    private HsConfig: HsConfig,
    private HsMapService: HsMapService,
    private FieAnalysisService: FieAnalysisService,
    private HsLanguageService: HsLanguageService,
    private HsShareUrlService: HsShareUrlService,
    private HsToastService: HsToastService,
    private HttpClient: HttpClient,
    private LiferayService: LiferayService,
    private HsDrawService: HsDrawService
  ) { 
  }


  init(): void {
    this.LiferayService.getSettings();
    this.hsPanelContainerService.create(HsSensorsComponent, {viewMode: 'sensornames'});
    this.assetsPath = `assets/`,
    this.HsConfig.update({
      assetsPath: this.assetsPath + 'hslayers-ng',
      proxyPrefix: "/proxy/",
      searchProvider: (q) => {
        return `/proxy/search/?q=${q}`
      },
      default_layers,
      layersInFeatureTable: [aoiLayer],
      project_name: 'erra/map',
      open_lm_after_comp_loaded: true,
      default_view: new View({
        center: transform([23.4218566, 56.4519995], 'EPSG:4326', 'EPSG:3857'), //Latitude longitude    to Spherical Mercator
        zoom: 14,
      }),
      popUpDisplay: 'click',
      advancedForm: true,
      datasources: datasourceList,
      panelWidths: {
        sensors: 600
      },
      sidebarPosition: 'left',
      panelsEnabled: {
        language: true,
        feature_crossfilter: false,
        feature_table: true,
        mapSwipe: true
      },
      componentsEnabled: {
        basemapGallery: false,
        mapSwipe: false
      },
      senslog: {
        liteApiPath: 'senslogOT',
        senslog2Path: 'senslogOT',
        url: 'https://sensor.lesprojekt.cz',
        user_id: 28, //Needed for senslogOT
        group: 'vilcini', //Needed for MapLogOT
        user: 'vilcini' //Needed for MapLogOT
      },
      translationOverrides,
      layerTooltipDelay: 1500,
      saveMapStateOnReload: false,
      timeDisplayFormat: 'dd.MM.yyyy'
      //,datasource_selector: {allow_add: false}
    });


    this.HsConfig.get().defaultDrawLayerPath = 'User generated';
    Object.assign(this.HsConfig.get().translationOverrides.lv, analysesLvTranslations);
    Object.assign(this.HsConfig.get().translationOverrides.en, analysesEnTranslations);
    setupLayers(this.HsLanguageService, this.HsShareUrlService, this.FieAnalysisService, this.HsToastService);
    meteoLegends(this.HsConfig.get().default_layers);
    this.HsDrawService.selectLayer(aoiLayer);
  }


  getHostname() {
    var url = window.location.href
    var urlArr = url.split("/");
    var domain = urlArr[2];
    return urlArr[0] + "//" + domain;
  }
}