import { Component } from '@angular/core';
import { HsConfig, HsEventBusService, HsLanguageService, HsLayerEditorService, HsLayoutService, HsMapService, HsPanelContainerService, HsShareUrlService, HsSidebarService, HsUtilsService } from 'hslayers-ng';
import './analysis/analysis.module';
import { mapClicked, vegetationLayers } from './layers/vegetation-layers';
import { meteoblueDimensionChanged } from './layers/meteo-layers';
import { MainService } from './main.service';
import { FieAnalysisPanelComponent } from './analysis/analysis.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FieAnalysisService } from './analysis/analysis.service';
import { landValue, opticalBaseMap, updateDimensionOptions, infrared } from './layers/layers';
import { HsSensorsService } from 'hslayers-sensors';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(

    private HsLayoutService: HsLayoutService,
    private HsEventBusService: HsEventBusService,
    private HsSidebarService: HsSidebarService,
    private HsShareUrlService: HsShareUrlService,
    public HsLanguageService: HsLanguageService,
    private HsUtilsService: HsUtilsService,
    public MainService: MainService,
    private HsSensorsService: HsSensorsService,
    private HsPanelContainerService: HsPanelContainerService,    
    private $http: HttpClient,
    private HsMapService: HsMapService,
    private HsLayerEditorService: HsLayerEditorService
  ) {
   
    this.HsLayoutService.setDefaultPanel('layermanager');
    this.HsSidebarService.sidebarLoad.subscribe(() => {
      this.HsPanelContainerService.create(FieAnalysisPanelComponent, {});
    })

    this.HsEventBusService.layermanagerDimensionChanges.subscribe((args) => {
      meteoblueDimensionChanged(args, this.HsShareUrlService, this.HsUtilsService)
    });
    this.HsEventBusService.mapClicked.subscribe((e) => {
      mapClicked(e, this.HsMapService, this.$http);
    });

    this.HsSidebarService.addButton({ panel: 'analysis', order: -1, title: this.HsLanguageService.getTranslation('Analysis'), description: HsLanguageService.getTranslation('Run analysis'), icon: 'icon-alertpay' })

    this.HsSidebarService.get().buttons.subscribe((buttons) => {
      this.HsSidebarService.setButtonImportancy(buttons, 'legend', false);
      this.HsSidebarService.setButtonImportancy(buttons, 'composition_browser', false);
      this.HsSidebarService.setButtonImportancy(buttons, 'datasource_selector', false);
      this.HsSidebarService.setButtonImportancy(buttons, 'permalink', false);
      this.HsSidebarService.setButtonImportancy(buttons, 'info', false);
      this.HsSidebarService.setButtonImportancy(buttons, 'print', false);
      this.HsSidebarService.setButtonImportancy(buttons, 'saveMap', false);
      const sensorsButton = buttons.find(b => b.panel == 'sensors')
      if(sensorsButton){
        sensorsButton.order = -0.5;
      }  
    })

    this.MainService.init();

    updateDimensionOptions([...vegetationLayers, opticalBaseMap, landValue, infrared], this.$http, this.HsUtilsService, this.HsEventBusService);
  }
}