import { Style, Stroke, Fill } from 'ol/style';
import { Legends } from './lesprojekt-legends';
import {DomSanitizer} from '@angular/platform-browser';
import * as moment from 'moment';

import { Component } from '@angular/core';
import { HsConfig, HsEventBusService, HsDrawService, HsUtilsService, HsLanguageService, HsLayoutService, HsDialogContainerService } from 'hslayers-ng';
import { FieAnalysisService } from './analysis.service';
import { InfoComponent } from './info.component';
import { MainService } from '../main.service';

@Component({
    selector: 'fie-analysis-panel',
    templateUrl: './analysis.directive.html',
    styles: [`
    .hsl .alert-primary {
        opacity: 0
    }
    .hsl .alert-primary.show {
        opacity: auto
    }`
    ]
})
export class FieAnalysisPanelComponent {
    loading = false;
    currentAnalysis = null;
    featureGroup = 'selected';
    analysisList: Array<any>;
    analysisMenuExpanded: boolean = true;
    analysisHint: any;
    lastClickedCoord: any;
    hintClosed = false;

    constructor(
        public HsConfig: HsConfig, 
        public HsLanguageService: HsLanguageService, 
        public FieAnalysisService: FieAnalysisService, 
        public HsLayoutService: HsLayoutService,
        public HsUtilsService: HsUtilsService, 
        public MainService: MainService,
        public HsDrawService: HsDrawService,
        public HsEventBusService: HsEventBusService,
        private sanitizer: DomSanitizer,
        private hsDialogContainerService: HsDialogContainerService
        ) {
        const descClickPointToRun = 'click_to_run';
        this.analysisList = [
            {
                name: 'meteogram_agro_hd', 'provider': 'meteoblue-image', description: descClickPointToRun
            },
            {
                name: 'meteogram_14day', 'provider': 'meteoblue-image', description: descClickPointToRun
            },
            {
                name: 'pictoprintDayUvRain', 'provider': 'meteoblue-image', description: descClickPointToRun
            },
            {
                name: 'meteogram_soiltraffic', 'provider': 'meteoblue-image', description: descClickPointToRun
            },
            {
                name: 'meteogram_agroSowing', 'provider': 'meteoblue-image', description: descClickPointToRun
            },
            {
                name: 'meteogram_agroSpraying', 'provider': 'meteoblue-image', description: descClickPointToRun
            },
            {
                name: 'history_frostrisk', 'provider': 'meteoblue-image', description: descClickPointToRun
            },
            {
                name: 'crop-weather-monitoring', 'provider': 'meteoblue-iframe-pyapi', 
                description: descClickPointToRun, 
                url: 'http://pyapi-staging.meteoblue.com/crop_weather_risk_monitoring?crop=${crop}&lat=${lat}&lon=${lon}&risk=${risk}&format=highchartshtml&years_clima_start=${years_clima_start}&years_clima_end=${years_clima_end}&years_actual_start=${years_actual_start}&years_actual_end=${years_actual_end}',
                parameters: {
                    "risk": { value: "drought", enum: ["drought", "frost", "heat wave"] },
                    "crop": { value: "winter_wheat", enum: ["cotton","maize","soybeans","spring_barley","winter_barley","summer_wheat","sunflower","vineyards","winter_wheat","potatoes"] },
                    "years_clima_start": { value: 1985 },
                    "years_clima_end": { value: 2021 },
                    "years_actual_start": { value: 2011 },
                    "years_actual_end": { value: 2021 },
                    "month_start": { value: 4 },
                    "month_end": { value: 8 }
                },
            },
            {
                name: 'protected-areas', url: 'https://gis.lesprojekt.cz/smartagrihubs/analysis-2.4.2',
                provider: 'lesprojekt',
                description: 'protected_areas_hint',
                parameters: {
                    "offset": { value: 5 },
                },
                style: function (feature, resolution) {
                    return [new Style({
                        stroke: new Stroke({
                            color: '#005CB6',
                            width: 0.5
                        }),
                        fill: new Fill({
                            color: feature.get('Permit') ? 'rgba(117, 214, 132, 0.7)' : 'rgba(211, 84, 84, 0.7)'
                        })
                    })]
                },
                legend: this.sanitizer.bypassSecurityTrustHtml(Legends.legend242)
            },
            {
                name: 'ndvi-average-daily-change', url: 'https://gis.lesprojekt.cz/smartagrihubs/analysis-2.4.6',
                provider: 'lesprojekt',
                description: 'ndvi-average-daily-change-hint',
                parameters: {
                    "date": { type: 'date', 
                        value: {
                            year: (moment()).year(), 
                            month: (moment()).month() + 1, 
                            day: (moment()).date()
                        } 
                    },
                },
                style: (feature, resolution) => {
                    return [new Style({
                        stroke: new Stroke({
                            color: '#005CB6',
                            width: 0.5
                        }),
                        fill: new Fill({
                            color: this.HsUtilsService.rainbow(0.07 + 0.08, feature.get('raster_val') + 0.08, "0.7")
                        })
                    })]
                },
                legend: this.sanitizer.bypassSecurityTrustHtml(Legends.legend246)
            },
            {
                name: 'fertilization-map', url: 'https://gis.lesprojekt.cz/smartagrihubs/analysis-2.4.5',
                provider: 'lesprojekt',
                description: 'fertilization_hint',
                parameters: {
                    "fertilizationMethod": { value: "add", enum: ['add', 'comp'] },
                    "fertilizerVolume": { value: 111 },
                },
                style: (feature, resolution) => {
                    return [new Style({
                        stroke: new Stroke({
                            color: '#000',
                            width: 0.5
                        }),
                        fill: new Fill({
                            color: this.HsUtilsService.rainbow(0.015, feature.get('value'), "0.7")
                        })
                    })]
                },
                legend: this.sanitizer.bypassSecurityTrustHtml(Legends.legend245)
            }
        ];

        /*for(let i=0; i<=6; i++){
            const calculated = ((0.07 - (-0.08)) / 6 * i + (-0.08));
            console.log(`{ value: ${calculated.toFixed(3)}, color: "${this.HsUtilsService.rainbow(0.07 + 0.08, calculated + 0.08, "0.7")}" },`);
        }*/
      

        this.HsEventBusService.mapClicked.subscribe(this.HsUtilsService.debounce((data) => {
            if (this.HsLayoutService.get('default').mainpanel == 'analysis' && this.currentAnalysis && this.currentAnalysis.name) {
                if (document.querySelector('#info-dialog')) {
                    var parent = document.querySelector('#info-dialog').parentElement;
                    parent.parentElement.removeChild(parent);
                }
                switch (this.currentAnalysis.provider) {
                    case 'meteoblue-image':
                    case 'meteoblue-image-pyapi':
                    case 'meteoblue-iframe-pyapi':
                        this.lastClickedCoord = data.coordinates.epsg4326Coordinate;
                        this.runMeteoBlueAnalysis(this.lastClickedCoord);
                        break;
                }
            }
        }, 500, false, this))
    }

    private async runMeteoBlueAnalysis(coord): Promise<void> {
        const placeName = await this.FieAnalysisService.getGeonameAtCoordinate(coord)   
        const url = this.sanitizer.bypassSecurityTrustResourceUrl(await this.FieAnalysisService.getMeteoblueResults(coord,
            this.currentAnalysis.name,
            placeName,
            this.currentAnalysis
        ));
        this.hsDialogContainerService.create(InfoComponent, { url, analysis: this.currentAnalysis });   
    }

    activateAoiDraw() {
        this.HsDrawService.get('default').selectedLayer = this.getAoiLayer();
        this.HsDrawService.get('default').source = this.HsDrawService.get('default').selectedLayer.getSource();
        this.HsDrawService.get('default').type = 'Polygon';
        this.HsDrawService.get('default').highlightDrawButton = true;
        this.HsDrawService.activateDrawing({
            onDrawStart: null,
            onDrawEnd: null,
            onSelected: null,
            onDeselected: null,
            changeStyle: null,
            drawState: true,
        })
    }

    translateHint(description) {
        return this.sanitizer.bypassSecurityTrustHtml(this.HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS_DESCRIPTIONS', description))
    }

    chooseAnalysis(currentAnalysis) {
        this.currentAnalysis = currentAnalysis;
        this.analysisMenuExpanded = false;
        this.analysisHint = this.translateHint(this.currentAnalysis.description);
        if (this.currentAnalysis.name == 'protected-areas') {
            Legends.categs242[0].title = this.HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS_LEGEND', 'Allowed');
            Legends.categs242[1].title = this.HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS_LEGEND', 'Prohibited');
            this.currentAnalysis.legend = this.sanitizer.bypassSecurityTrustHtml(Legends.create242());
        }
        switch (this.currentAnalysis.provider) {
            case 'meteoblue-image':
                if(this.lastClickedCoord) {
                    this.runMeteoBlueAnalysis(this.lastClickedCoord);
                }
                break;
        }
        this.hintClosed = false;
    }

    closeHint(){
        this.hintClosed = true;
    }

    runAnalysis() {
        const aoiLayer = this.getAoiLayer();
        const analysis = this.currentAnalysis;
        this.FieAnalysisService.runLesprojektAnalysis({
            url: analysis.url,
            layer: aoiLayer,
            style: analysis.style,
            parameters: analysis.parameters,
            name: analysis.name,
            featureGroup: this.featureGroup
        });
    }

    getAoiLayer() {
        return this.HsConfig.get().default_layers.filter(l =>
            l.get('name') == 'areas_of_interest')[0]
    }

    isVisible() {
        return this.HsLayoutService.panelVisible('analysis');
    }
}