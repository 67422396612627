import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {HslayersModule} from 'hslayers-ng';
import {NgModule} from '@angular/core';
import {AnalysisModule} from './analysis/analysis.module';
import {HsSensorsModule} from 'hslayers-sensors';

@NgModule({
  imports: [BrowserModule, HslayersModule, AnalysisModule, HsSensorsModule],
  declarations: [AppComponent],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
