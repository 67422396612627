import { Tile } from 'ol/layer';
import { XYZ, TileWMS} from 'ol/source';
import genLabelStyle from './gen-label-style';
import { Style, Stroke, Fill, Circle } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import { HttpClient } from '@angular/common/http';

var labelStyle = genLabelStyle;
const assetsPath = (<any> window).assetsPath ? (<any> window).assetsPath : (window.location.hostname.indexOf('local.groundwater') > -1
    ? `assets/`
    : 'static/assets/');

export const vegetationLayers = [
    new Tile({
        properties: {
          title: "NDVI index (Vilcini)",
          from_composition: true,
          dimensions: {
            time: {
                value: '2020-11-20',
                name: 'time',
                values: ['2020-11-20']
            }
          },
          path: 'Vegetation indexes and satellite imagery',
          inlineLegend: true,
          legendImage: assetsPath + 'images/NDVI_legend.png',
          editor: { editable: false },
        },
        source: new XYZ({
            attributions: '&copy; <a href="http://www.baltsat.lv/">Baltic Satellite Service</a>, <a href="https://www.esa.int/">European Space Agency - ESA</a>',
            url: 'https://wms.forestradar.com/tiles-v1/fie-xNFwHfJdIR1dCtA7kJ1K8g/{time}-NDVI/{z}/{x}/{y}.png',
        }),        
        maxZoom: 18,
        visible: false,
        opacity: 1
    }),
    new Tile({
        properties: {
          title: "MSAVI2 index (Vilcini)",
          from_composition: true,
          dimensions: {
            time: {
                value: '2020-11-20',
                name: 'time',
                values: ['2020-11-20']
            }
          }, 
          path: 'Vegetation indexes and satellite imagery',
          inlineLegend: true,
          legendImage: assetsPath + 'images/NDVI_legend.png',  
          editor: { editable: false },
        },
        source: new XYZ({
            attributions: '&copy; <a href="http://www.baltsat.lv/">Baltic Satellite Service</a>, <a href="https://www.esa.int/">European Space Agency - ESA</a>',
            url: 'https://wms.forestradar.com/tiles-v1/fie-xNFwHfJdIR1dCtA7kJ1K8g/{time}-MSAVI2/{z}/{x}/{y}.png',
        }),       
        maxZoom: 18,
        visible: false,
        opacity: 1
    }),
    new Tile({
      properties: {
        title: 'Yield productivity zones',
        abstract: 'yieldLayerAbstract',
        from_composition: true,
        queryCapabilities: false,
        path: 'Vegetation indexes and satellite imagery',
        legendImage: assetsPath + 'images/yield.png',
        inlineLegend: true,
      },
      source: new TileWMS({
        url: 'http://51.15.45.95:8080/geoserver/fie20/wms',
        params: {
          LAYERS: 'fie20:yp_vilcini',
          INFO_FORMAT: undefined,
          FORMAT: 'image/png; mode=8bit',
        },
        crossOrigin: 'anonymous',
      }),
      visible: false,
    })]

export const vegetationLayersLesprojekt = [
    new Tile({
      properties: {
        title: 'NDWI (green band)',
        abstract: '',
        from_composition: true,
        queryCapabilities: true,
        path: 'Vegetation indexes and satellite imagery',
        inlineLegend: true,
      },
      source: new TileWMS({
        url: 'https://gis.lesprojekt.cz/geoserver/S2_2020/wms',
        params: {
          LAYERS: '34VFH_NDWI',
          INFO_FORMAT: undefined,
          FORMAT: 'image/png; mode=8bit',
        },
        crossOrigin: 'anonymous',
      }),
      
      visible: false,
    }),

    new Tile({
      properties: {
        title: 'NDWI (SWIR band)',
        abstract: '',
        from_composition: true,
        queryCapabilities: true, 
        path: 'Vegetation indexes and satellite imagery',
        inlineLegend: true,
      },
      source: new TileWMS({
        url: 'https://gis.lesprojekt.cz/geoserver/S2_2020/wms',
        params: {
          LAYERS: '34VFH_NDWI_SWIR',
          INFO_FORMAT: undefined,
          FORMAT: 'image/png; mode=8bit',
        },
        crossOrigin: 'anonymous',
      }),
      visible: false,
    }),
    
];

var ndviStyle = [new Style({
  image: new Circle({
    radius: 5,
    stroke: new Stroke({
      color: '#fff',
    }),
    fill: new Fill({
      color: '#3399CC',
    }),
  })
}), labelStyle];


export function mapClicked(e, HsMapService, $http: HttpClient) {
    const c = e.coordinates.epsg4326Coordinate;
      vegetationLayers.filter(vegLayer => vegLayer.get('title').indexOf('NDVI') > -1 && vegLayer.getVisible())
        .forEach(async (ndviLayer) => {
          const response: any = await $http.get(`https://lv2.forestradar.com/values-v2/zs/ndvi/${ndviLayer.get('dimensions').time.value}/${c[1]}/${c[0]}?token=dmo-9EDp-Q7Nutoz_Mn4QOtv_A`).toPromise();
          const PICKED_NDVI_TITLE = 'Picked NDVI values';
          let layer = HsMapService.findLayerByTitle(PICKED_NDVI_TITLE);
          if (layer == undefined || layer == null) {
            layer = new VectorLayer({
              properties: {
                title: PICKED_NDVI_TITLE,
                popUp: {
                  attributes: [
                    {
                      attribute: 'values',
                      displayFunction: (values) =>
                        values.map(v => `${v.value.toFixed(3)} at ${v.date.substring(6, 8)}.${v.date.substring(4, 6)}.${v.date.substring(0, 4)}`).join(', ')
                    }
                  ]
                },
                synchronize: false,
                editor: {
                  editable: true
                },
                queryable: false,
                path: 'User generated',
              },
              source: new VectorSource({}),
              style: function (feature) {
                const values = feature.get('values').map(v => `NDVI ${v.value.toFixed(2)} at ${v.date}`)
                labelStyle.getText().setText(values.join('\n'));
                labelStyle.getText().setOffsetY(values.length * -10)
                return ndviStyle;
              },
            });
            HsMapService.map.addLayer(layer)
          }
          const existingFeatures = HsMapService.map.getFeaturesAtPixel(e.pixel).filter(f => HsMapService.getLayerForFeature(f) == layer);
          const date = ndviLayer.get('dimensions').time.value;
          if (existingFeatures.length > 0) {
            const values = existingFeatures[0].get('values');
            if (values.filter(v => v.date == date).length == 0) {
              values.push({ value: response.value, date });
              existingFeatures[0].set('redraw', Math.random());
            }
          } else {
            if (response.value == null) return;
            layer.getSource().addFeature(new Feature({
              values: [{ value: response.value, date }],
              name: `WGS-84 Lat, Long: ${c.map(cord => cord.toFixed(7)).join(' ')}`,
              geometry: new Point(e.coordinate)
            }))
          }
        })
}

