import { Injectable } from '@angular/core';
import { transform, transformExtent } from 'ol/proj';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {  HsMapService, HsCompositionsParserService, HsShareUrlService, HsUtilsService, HsConfig, HsEventBusService } from 'hslayers-ng';
import { CookieService } from 'ngx-cookie-service';
import { config, dimensions, meteoblueLayers, nowString } from './layers/meteo-layers';
import { Tile } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import { Image as ImageLayer } from 'ol/layer';
import meteoLegends from './layers/meteo-legends';
import {HsSensorsService} from 'hslayers-sensors';
import { aoiLayer, bookmarkLayer } from './layers/layers';

@Injectable({providedIn: 'root'})
export class LiferayService {
  assetsPath: string;
  constructor(

    private HsMapService: HsMapService,
    private HttpClient: HttpClient,
    private cookieService: CookieService,
    private HsCompositionsParserService: HsCompositionsParserService,
    private HsShareUrlService: HsShareUrlService,
    private HsUtilsService: HsUtilsService,
    private HsConfig: HsConfig,
    private HsSensorsService: HsSensorsService,
    private HsEventBusService: HsEventBusService
  ) { }


  async getSettings(){
    const authToken = await this.getAuthToken();
    this.cookieService.set('P_AUTH', authToken, undefined, '/');
    const user = await this.getCurrentLiferayUser(authToken);
    this.readComposition(authToken, user);
    this.readSenslogConfig(authToken, user);
  }

  private async readComposition(authToken: string, user: Object) {
    try {
      const composition = JSON.parse(await this.getCustomField(authToken, user, "MAP_COMPOSITION"));
      this.setupMeteoblueLayers(composition);
    } catch (ex){
      console.warn('No map composition specified');
    }    
  }

  private setupMeteoblueLayers(composition: any) {
    config.weatherMapExtent = [
      parseFloat(composition.extent[0]),
      parseFloat(composition.extent[1]),
      parseFloat(composition.extent[2]),
      parseFloat(composition.extent[3])
    ];
    const center = {
      x: (config.weatherMapExtent[0] + config.weatherMapExtent[2]) / 2,
      y: (config.weatherMapExtent[1] + config.weatherMapExtent[3]) / 2
    };
    //Expand extent 4 times
    config.weatherMapExtent[0] = center.x + (config.weatherMapExtent[0] - center.x) * 15;
    config.weatherMapExtent[2] = center.x + (config.weatherMapExtent[2] - center.x) * 15;
    config.weatherMapExtent[1] = center.y + (config.weatherMapExtent[1] - center.y) * 15;
    config.weatherMapExtent[3] = center.y + (config.weatherMapExtent[3] - center.y) * 15;
    const extent3857 = transformExtent(
      config.weatherMapExtent,
      'EPSG:4326',
      this.HsMapService.getCurrentProj()
    );
    for (const layerDef of composition.layers.filter(l => l.className == 'StaticImage')) {
      layerDef.extent = extent3857;
      const url = decodeURIComponent(layerDef.url);
      const params: any = this.HsShareUrlService.parse(url.split('?')[1]);
      params.latmin = config.weatherMapExtent[3];
      params.latmax = config.weatherMapExtent[1];
      params.lonmin = config.weatherMapExtent[0];
      params.lonmax = config.weatherMapExtent[2];
      params.time = nowString;
      layerDef.url = this.HsUtilsService.proxify(`${url.split('?')[0]}?${this.HsShareUrlService.stringify(params)}`);
    }
    this.HsCompositionsParserService.loadCompositionObject(composition, true);
    for (const layer of this.HsMapService.getLayersArray()) {
      if (this.HsUtilsService.instOf(layer, Tile)) {
        layer.set('inlineLegend', true);
      }
      if (this.HsUtilsService.instOf(layer, ImageLayer) && layer.getSource().getUrl().includes('meteoblue')) {
        layer.set('dimensions', dimensions);
        layer.set('inlineLegend', true);
      }
    }
    this.moveLayerToTop(this.HsSensorsService.get('default').layer);
    this.moveLayerToTop(aoiLayer);
    this.moveLayerToTop(bookmarkLayer, true);
    meteoLegends(this.HsMapService.getLayersArray('default'));
  }

  moveLayerToTop(layer: BaseLayer, issueUpdateEvent?: boolean){
    const maxZ = Math.max(
      ...this.HsMapService.getLayersArray('default').map((o) => o.getZIndex() || 0));
      layer.setZIndex(maxZ + 1);
    if(issueUpdateEvent){
      this.HsEventBusService.layerManagerUpdates.next({layer, app: 'default'});
    }
  }

  private async readSenslogConfig(authToken: string, user: Object) {
    try {
      const configString = await this.getCustomField(authToken, user, "SENSLOG_CONFIG");
      const senslogConfig = JSON.parse(configString);
      if (senslogConfig) {
        this.HsConfig.update({senslog: senslogConfig});
        this.HsSensorsService.getUnits('default');
      }
    } catch (ex){
      console.warn('No senslog config specified', ex);
    }
  }

  private async getCurrentLiferayUser(authToken: string) {
    const body = new HttpParams()
      .set('cmd', `{"/user/get-current-user":{}}`)
      .set('p_auth', authToken)
      .set('formDate', this.now());
    const currentUser = await this.invokeJsonWs(body);
    return currentUser;
  }

  private async invokeJsonWs(body: HttpParams){
    return this.HttpClient.post('/api/jsonws/invoke', body.toString(),
    {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    }).toPromise()
  }

  private now(): string {
    return ((new Date()).getTime()).toString();
  }

  private async getAuthToken() {
    const rootResponse = await this.HttpClient.get('/', { responseType: 'text' }).toPromise();
    let matches = rootResponse.match(/Liferay.authToken="(.*?)"/);
    let authToken = ''; 
    if(matches) 
      authToken = matches[1];
    else {
      matches = rootResponse.match(/Liferay.authToken = '(.*?)'/);
      if(matches) 
        authToken = matches[1];
    }
    return authToken;
  }

  private async getCustomField(authToken: string, user: any, field: string): Promise<any> {
    const body = new HttpParams()
      .set('cmd', `{"/expandovalue/get-data":{}}`)
      .set('p_auth', authToken)
      .set('formDate', this.now())
      .set('companyId', user.companyId)
      .set('className', "com.liferay.portal.kernel.model.User")
      .set('tableName', "CUSTOM_FIELDS")
      .set('columnName', field)
      .set('classPK', user.userId);
    const value =  await this.invokeJsonWs(body);
    return value
  }
}