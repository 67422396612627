import { transformExtent } from 'ol/proj';
import { Image as ImageLayer } from 'ol/layer';
import Static from 'ol/source/ImageStatic.js';
import * as moment from 'moment';
export const config = {
  weatherMapExtent: [19.918213, 58.274843, 29.091797, 55.631097]
};
export const nowString = moment().subtract(2, 'minutes').utc().format('YYYYMMDDHH');
export const dimensions = {
  time: { label: 'Local time', type: 'datetime', value: moment().startOf('minute').toDate(), onlyInEditor: true },
  level: { label: 'Level hPa', value: 'surface', values: ['surface', '950mb - 500m', '900mb - 1km', '850mb - 1.5km', '800mb - 2km', '750mb - 2.5km', '700mb - 3km', '650mb - 3.6km', '600mb - 4.2km', '500mb - 5.5km', '400mb - 7.2km', '350mb - 8.1km', '300mb - 9.2km', '250mb - 10.4km', '200mb - 11.8km'], 
  availability: function(layer){
    return layer.get('title') != 'Temperature observations'
  } }
};

export const meteoblueLayers = [
  new ImageLayer({
    properties: {
      title: 'Cloud cover / precipitation',
      from_composition: true,
      weatherMapExtent: config.weatherMapExtent,
      path: 'Meteo',
      inlineLegend: true,
      dimensions
    },
    opacity: 0.6,
    source: new Static({
      attributions: 'Meteoblue',
      url: `/proxy/https://my.meteoblue.com/visimage/webmap?variable=precipitation3h_cloudcover_pressure&time=${nowString}&level=surface&imgxs=2500&imgys=2500&latmin=${config.weatherMapExtent[3]}&latmax=${config.weatherMapExtent[1]}&lonmin=${config.weatherMapExtent[0]}&lonmax=${config.weatherMapExtent[2]}&countryoverlay=1&colorbar=0`,
      imageExtent: transformExtent(config.weatherMapExtent, 'EPSG:4326', 'EPSG:3857')
    }),
    visible: false,
  }),
  new ImageLayer({
    properties: {
      title: 'Temperature',
      from_composition: true,
      weatherMapExtent: config.weatherMapExtent,
      path: 'Meteo',
      inlineLegend: true,
      dimensions,
      time: moment(),
    },
    opacity: 0.6,
    source: new Static({
      attributions: 'Meteoblue',
      url: `/proxy/https://my.meteoblue.com/visimage/webmap?&variable=temperature&time=${nowString}&level=surface&imgxs=2500&imgys=2500&latmin=${config.weatherMapExtent[3]}&latmax=${config.weatherMapExtent[1]}&lonmin=${config.weatherMapExtent[0]}&lonmax=${config.weatherMapExtent[2]}&countryoverlay=1&colorbar=0`,
      imageExtent: transformExtent(config.weatherMapExtent, 'EPSG:4326', 'EPSG:3857')
    }),
    visible: false,
  }),
  new ImageLayer({
    properties: {
      title: 'Wind',
      from_composition: true,
      inlineLegend: true,
      weatherMapExtent: config.weatherMapExtent,
      time: moment(),
      path: 'Meteo',
      dimensions
    },
    opacity: 0.6,
    source: new Static({
      attributions: 'Meteoblue',
      url: `/proxy/https://my.meteoblue.com/visimage/webmap?&variable=wind_streamlines&time=${nowString}&level=surface&imgxs=2500&imgys=2500&latmin=${config.weatherMapExtent[3]}&latmax=${config.weatherMapExtent[1]}&lonmin=${config.weatherMapExtent[0]}&lonmax=${config.weatherMapExtent[2]}&countryoverlay=1&colorbar=0`,
      imageExtent: transformExtent(config.weatherMapExtent, 'EPSG:4326', 'EPSG:3857')
    }),
    visible: false
  }),
  new ImageLayer({
    properties: {
      title: 'Satellite and pressure',
      from_composition: true,
      weatherMapExtent: config.weatherMapExtent,
      inlineLegend: false,
      time: moment(),
      path: 'Meteo',
      dimensions
    },
    opacity: 0.6,
    source: new Static({
      attributions: 'Meteoblue',
      url: `/proxy/https://my.meteoblue.com/visimage/webmap?&variable=obssat_pressure&time=${nowString}&level=surface&imgxs=2500&imgys=2500&latmin=${config.weatherMapExtent[3]}&latmax=${config.weatherMapExtent[1]}&lonmin=${config.weatherMapExtent[0]}&lonmax=${config.weatherMapExtent[2]}&countryoverlay=1&colorbar=1`,
      imageExtent: transformExtent(config.weatherMapExtent, 'EPSG:4326', 'EPSG:3857')
    }),
    visible: false,   
  }),
  new ImageLayer({
    properties: {
      title: 'Temperature observations',
      from_composition: true,
      weatherMapExtent: config.weatherMapExtent,
      inlineLegend: false,
      path: 'Meteo',
      dimensions
    },
    opacity: 0.6,
    source: new Static({
      attributions: 'Meteoblue',
      url: `/proxy/https://my.meteoblue.com/visimage/webmap?&variable=obstemp&level=surface&imgxs=2500&imgys=2500&latmin=${config.weatherMapExtent[3]}&latmax=${config.weatherMapExtent[1]}&lonmin=${config.weatherMapExtent[0]}&lonmax=${config.weatherMapExtent[2]}&countryoverlay=1&colorbar=1`,
      imageExtent: transformExtent(config.weatherMapExtent, 'EPSG:4326', 'EPSG:3857')
    }),
    visible: false,
  })
]

export function meteoblueDimensionChanged(args, HsPermalinkUrlService, HsUtilsService){
  const layer = args.layer;
  let src = layer.getSource();
  if (src.getUrl && src.getUrl().indexOf('meteoblue.com') > -1) {
    let params = HsPermalinkUrlService.parse(src.getUrl().split('?')[1]);
    if (layer.cachedSources == undefined) {
      layer.cachedSources = {};
      layer.cachedSources[params.time] = layer.getSource();
    }
    Object.keys(layer.get('dimensions')).forEach(key => {
      if (layer.get('dimensions')[key] == args.dimension) {
        let formatted = args.dimension.value;
        switch (key) {
          case 'time':
            formatted = (moment(args.dimension.value)).utc().format('YYYYMMDDHH');
            break;
          case 'level':
            formatted = args.dimension.value.split(' - ')[0];
            break;
        }
        params[key] = formatted;
      }
    })

    const dimensionCombination = Object.keys(layer.get('dimensions')).map(key => layer.get('dimensions')[key].value).join(',');

    if (layer.cachedSources[dimensionCombination]) {
      layer.setSource(layer.cachedSources[dimensionCombination]);
    } else {
      layer.setSource(new Static({
        attributions: 'Meteoblue',
        url: HsUtilsService.proxify(`${src.getUrl().split('?')[0]}?${HsPermalinkUrlService.stringify(params)}`),
        imageExtent: src.getImageExtent()
      }));
      layer.cachedSources[dimensionCombination] = layer.getSource();
    }

  }

}